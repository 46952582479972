import type { AggregateProjectModel } from '../models/AggregateProjectModel';
import type {
    CreateProjectUpdateRequest,
    projectProgressModel,
    projectStatusModel,
    ProjectUpdateModel,
    ProjectUpdatesModel,
    ProjectUpdatesModelEntry,
} from '../models/ProjectUpdateModel';
import {
    useGet,
    createMutation,
    simpleGet,
    getAsync,
    mutateCache,
    UseGetResponse,
    GetConfiguration,
    neverRevalidate,
} from '../hooks/useApi';
import { useEffect, useState } from 'react';
import {
    CompositeGetConfiguration,
    useCompositeGet,
} from '../hooks/useCompositeGet';
import {
    CreateProjectRequest,
    ProjectModel,
    UpdateProjectRequest,
} from '../models/ProjectModel';
import {
    StartAiProcessRequest,
    StartFolderAiProcessRequest,
    StartVideoSliceProcess,
} from '../models/AiProcessModel';
import {
    TaskLogModel,
    TaskModel,
    TaskResult,
    TaskResultModel,
    TaskState,
} from '../models/TaskModel';
import { AiAnalysisType, AiTypeUpdateRequest } from '../models/AiAnalysisType';
import { exportProject } from './project/exportProject';
import { exportReport } from './project/exportReport';
import { safeUrl } from '../utils/safeUrl';
import { templateApi } from './templateApi';
import { Queue } from '../utils/queue';
import { userTask } from './userApi';
import { useRefresh } from '../hooks/useRefresh';
import { useNewData } from '../hooks/useNewData';

export interface ProjectFilesOption {
    points?: boolean;
    limit?: number;
    after?: string;
    decompile?: boolean;
}

export const projectApi = {
    mutate: {
        updateFileMeta: (projectId: string, fileId: string) => {
            const get = projectApi.get.fileMeta(projectId, fileId);
            return createMutation<
                CreateProjectUpdateRequest,
                { data: ProjectUpdateModel | undefined }
            >(
                async ({ authenticated }, state, opts) =>
                    authenticated.post(get.key, state, opts),
                get
            );
        },
        createProject: createMutation<
            CreateProjectRequest,
            { data: ProjectModel }
        >(async ({ authenticated }, data, opts) =>
            authenticated.post('/project', data, opts)
        ),
        updateProject: createMutation<
            UpdateProjectRequest,
            { data: UpdateProjectRequest }
        >(async ({ authenticated }, data, opts) =>
            authenticated.post(safeUrl`/project/${data.projectId}`, data, opts)
        ),
        removeProject: createMutation<
            { projectId: string },
            { data: ProjectModel }
        >(async ({ authenticated }, { projectId }, opts) =>
            authenticated.delete(safeUrl`/project/${projectId}`, opts)
        ),
        startAiProcess: (projectId: string) =>
            createMutation<
                Omit<StartAiProcessRequest, 'projectId'>,
                { data: TaskModel | undefined }
            >({
                mutate: async ({ authenticated }, data, opts) =>
                    (
                        await authenticated.post(
                            '/ai-process-start',
                            { ...data, projectId },
                            opts
                        )
                    ).data,
                get: projectApi.get.projectTask(projectId),
                effect() {
                    mutateCache(userTask.key);
                },
            }),
        startVideoSlice: createMutation<
            StartVideoSliceProcess,
            { data: TaskModel }
        >({
            mutate: async ({ authenticated }, data, opts) =>
                (await authenticated.post('/video-to-images', data, opts)).data,
            effect({ data }, {}) {
                mutateCache(projectApi.get.projectTask(data.projectId).key);
                mutateCache(userTask.key);
            },
        }),
        cancelProcess: createMutation<
            { taskId: string; projectId: string },
            { success: true }
        >({
            mutate: async ({ authenticated }, { taskId }, opts) =>
                (
                    await authenticated.post(
                        safeUrl`/task/${taskId}/cancel`,
                        {},
                        opts
                    )
                ).data,
            effect({}, { projectId }) {
                mutateCache(projectApi.get.projectTask(projectId).key);
                mutateCache(userTask.key);
            },
        }),
        startFolderAiProcess: createMutation<
            StartFolderAiProcessRequest,
            { data: TaskModel }
        >({
            mutate: async ({ authenticated }, data, opts) =>
                (
                    await authenticated.post(
                        '/ai-folder-process-start',
                        data,
                        opts
                    )
                ).data,
            effect({ data }) {
                mutateCache(projectApi.get.projectTask(data.projectId).key);
                mutateCache(userTask.key);
            },
        }),
        updateAiType: (tenantId: string) => {
            return createMutation<AiTypeUpdateRequest, { data: TaskModel }>(
                async ({ authenticated }, data, opts) =>
                    (
                        await authenticated.put(
                            `/admin/ai-analysis-types-tenants/${tenantId}`,
                            data,
                            opts
                        )
                    ).data
            );
        },
        exportProject,
        exportReport,
    } as const,
    get: {
        projects: simpleGet<ProjectModel[]>`/projects`,
        aiAnalysisTypes: simpleGet<AiAnalysisType[]>`/ai-analysis-types`,
        allAiAnalysisTypes: simpleGet<
            AiAnalysisType[]
        >`/admin/ai-analysis-types`,
        tenantAiAnalysisTypes: (tenantId: string) =>
            simpleGet<
                AiAnalysisType[]
            >`/admin/ai-analysis-types-tenants/${tenantId}`,
        project: (projectId: string) =>
            simpleGet<ProjectModel | null>`/project/${projectId}`,
        folderProjects: (folderId: string, withChildren?: boolean) => {
            const params = new URLSearchParams();
            params.append('children', withChildren ? '1' : '0');
            return simpleGet<
                ProjectModel[]
            >`/projects/folder/${folderId}?${params}`;
        },
        projectProgress: (projectId: string) => {
            return simpleGet<projectProgressModel>`/project/${projectId}/status`;
        },
        projectFiles: (projectId: string, opts?: ProjectFilesOption) => {
            const params = new URLSearchParams();
            if (opts?.limit) {
                params.append('limit', opts.limit.toString());
            }
            if (opts?.after) {
                params.append('after', opts.after);
            }
            if (opts?.points) {
                params.append('points', '1');
            }
            if (opts?.decompile) {
                params.append('decompile', '1');
            }
            return simpleGet<ProjectUpdatesModel>`/project/${projectId}/files?${params}`;
        },
        projectTask: (projectId: string) =>
            simpleGet<TaskModel | undefined>`/project/${projectId}/task`,
        tasks: (start: string, end: string, projectId?: string) => {
            const params = new URLSearchParams();
            params.append('start', start);
            params.append('end', end);
            return projectId
                ? simpleGet<
                      TaskLogModel[] | null
                  >`/project/${projectId}/tasks?${params}`
                : simpleGet<TaskLogModel[] | null>`/project/tasks?${params}`;
        },
        getVideoSliceStatus: (folderId: string) =>
            simpleGet<TaskModel[]>`/folder/${folderId}/task/video`,
        fileMeta: (projectId: string, fileId: string) =>
            simpleGet<
                ProjectUpdateModel | undefined
            >`/project/${projectId}/file/${fileId}`,
        taskUpdates: ({
            aiTaskId,
            since,
            before,
            order,
        }: {
            aiTaskId: string;
            since?: string | null;
            before?: string | null;
            order?: 'asc' | 'desc';
        }) => {
            const params = new URLSearchParams();
            if (since) {
                params.append('since', since);
            }
            if (before) {
                params.append('before', before);
            }
            if (order) {
                params.append('order', order);
            }
            return simpleGet<TaskResultModel>`/task/${aiTaskId}/updates?${params}`;
        },
        report: ({
            projectId,
            skipEmpty,
            includeUrl,
        }: {
            projectId: string;
            skipEmpty: boolean;
            includeUrl: boolean;
        }) =>
            simpleGet<{
                link: string;
            }>`/project/${projectId}/report?skipEmpty=${
                skipEmpty ? '1' : '0'
            }&includeUrl=${includeUrl ? '1' : '0'}`,
    } as const,
} as const;

export interface ProjectTaskProps {
    projectId: string | undefined;
    onTaskUpdate?: (
        projectId: string,
        task: TaskModel,
        mode: 'append' | 'prepend',
        data?: TaskResult
    ) => void;
    lastUpdate: {
        [aiTaskId: string]: {
            before?: string | null;
            since?: string | null;
        };
    };
    isDoneLoading: boolean;
}

const INT_IDLE = 5000;
const INT_ACTIVE = 500;

export function isTaskRunning(status: TaskState | undefined) {
    return status !== TaskState.error && status !== TaskState.success;
}

export const useProjectTask = (task: TaskModel, tryLoadMore: boolean) => {
    const [log, setLog] = useState<TaskResult[]>([]);
    const [hasBefore, setHasBefore] = useState(true);
    const { aiTaskId, status } = task;
    useEffect(() => {
        setLog([]);
        setHasBefore(true);
    }, [aiTaskId]);
    const isEmpty = log.length === 0;
    const before = log[0]?.updatedAt ?? null;
    const latest = log[log.length - 1];
    const since = latest?.updatedAt ?? null;
    const hasSince = !(
        latest?.aiTaskId === aiTaskId && latest?.event === 'END'
    );
    const newLog = useGet(
        hasSince && aiTaskId && since
            ? projectApi.get.taskUpdates({ aiTaskId, since, order: 'asc' })
            : null
    );
    const oldLog = useGet(
        hasBefore && aiTaskId && tryLoadMore
            ? projectApi.get.taskUpdates({ aiTaskId, before, order: 'desc' })
            : null
    );
    useRefresh(
        projectApi.get.projectTask(task.projectId),
        isTaskRunning(status) ? INT_ACTIVE : INT_IDLE
    );
    useNewData(
        newLog,
        (data) => {
            if (data.data.length > 0) {
                setLog((log) => [...log, ...data.data]);
                return true;
            }
        },
        []
    );
    useNewData(
        oldLog,
        (data) => {
            if (data.data.length > 0) {
                setLog((log) => [...data.data.concat().reverse(), ...log]);
                return true;
            } else if (!(isTaskRunning(status) && isEmpty)) {
                setHasBefore(false);
                return true;
            }
        },
        [status, isEmpty]
    );
    useEffect(() => {
        if (!newLog.key) return;
        if (newLog.isLoading) return;
        newLog.mutate();
    }, [newLog.key, task.updatedAt, newLog.isLoading]);
    useEffect(() => {
        if (!isEmpty) return;
        if (!oldLog.key) return;
        if (oldLog.isLoading) return;
        oldLog.mutate();
    }, [isEmpty, oldLog.key, task.updatedAt, oldLog.isLoading]);
    return {
        log,
        hasBefore,
        hasSince,
        isLoading: hasBefore && oldLog.isLoading,
    };
};

export const useProjectData = (
    projectId?: string,
    config?: CompositeGetConfiguration<
        string | null | undefined,
        AggregateProjectModel
    >
) => {
    return useCompositeGet(() => projectId ?? null, {
        render(projectId, { useGet }) {
            const project = useGet(
                projectId ? projectApi.get.project(projectId) : null,
                {
                    keepPreviousData: true,
                }
            );
            const aiTemplateId = project?.data?.aiTemplateId ?? null;
            return {
                project: project?.data,
                aiTemplate: useGet(
                    aiTemplateId
                        ? templateApi.get.aiTemplate(aiTemplateId)
                        : null
                ),
                types: useGet(projectApi.get.aiAnalysisTypes),
            };
        },
        compile({ project, types, aiTemplate }): AggregateProjectModel {
            const aiAnalysisTypeId =
                aiTemplate?.data?.aiAnalysisTypeId ?? project?.aiAnalysisTypeId;
            return {
                project,
                labels: aiTemplate?.data?.labels ?? [],
                aiAnalysisType: types.data.find(
                    (type) => type?.aiAnalysisTypeId === aiAnalysisTypeId
                )!,
            };
        },
        config,
    });
};

export interface EachProjectFilesOptions {
    points: boolean;
    queue: Queue;
}

function last<T>(array: T[]): T | undefined {
    return array[array.length - 1];
}

export const eachProjectFiles = async (
    projectId: string,
    opts: EachProjectFilesOptions,
    callback: (
        project: ProjectModel,
        entry: ProjectUpdatesModelEntry,
        index: number,
        total: number
    ) => Promise<void> | void
): Promise<void> => {
    const { queue } = opts;
    const limit = 10;
    const loadPage = async (
        config: { signal: AbortSignal },
        index: number,
        after: string | undefined
    ) => {
        const resp = await getAsync(
            projectApi.get.projectFiles(projectId, {
                limit,
                points: opts.points,
                after,
            }),
            config
        );
        if (!resp?.data) {
            throw new Error('プロジェクト情報の取得に失敗しました');
        }
        const { items, total, project } = resp.data;
        for (let item of items) {
            const i = index++;
            queue.add(async () => await callback(project, item, i, total));
        }
        if (index < total && items.length > 0) {
            queue.add((config) =>
                loadPage(config, index, last(items)?.item.fileId)
            );
        }
    };
    queue.add((config) => loadPage(config, 0, undefined));
};

export function useProjectAiType(
    projectId: string,
    opts?: GetConfiguration
): UseGetResponse<AiAnalysisType, Error> {
    const project = useGet(projectApi.get.project(projectId), opts);
    const aiTemplateId = project.data?.data?.aiTemplateId;
    const aiTemplate = useGet(
        aiTemplateId ? templateApi.get.aiTemplate(aiTemplateId) : null,
        opts
    );
    const aiAnalysisTypes = useGet(projectApi.get.aiAnalysisTypes, opts);
    const aiAnalysisTypeId = project.data?.data
        ? aiTemplate.data?.data.aiAnalysisTypeId ??
          project.data.data.aiAnalysisTypeId
        : null;
    return {
        isLoading:
            project.isLoading ||
            aiTemplate.isLoading ||
            aiAnalysisTypes.isLoading,
        error: project.error ?? aiTemplate.error ?? aiAnalysisTypes.error,
        isValidating:
            project.isValidating ||
            aiTemplate.isValidating ||
            aiAnalysisTypes.isValidating,
        lastUpdate: Math.min(
            project.lastUpdate,
            aiTemplate.lastUpdate,
            aiAnalysisTypes.lastUpdate
        ),
        data:
            aiAnalysisTypeId && aiAnalysisTypes.data
                ? aiAnalysisTypes.data.data.find(
                      (type) => type.aiAnalysisTypeId === aiAnalysisTypeId
                  )
                : undefined,
        mutate() {
            throw new Error('No direct mutation supported');
        },
    };
}
